import React, { useState } from 'react';
import { Table, SIZE, DIVIDER } from "baseui/table-semantic";
import { Spinner } from "baseui/spinner";
import { FlexGrid } from "baseui/flex-grid";
import { useStyletron } from 'baseui';
import { Pagination, SIZE as PaginationSize } from "baseui/pagination";
import formatDate from '@/utils/formatDate';
import { Tag, TagKind } from "baseui/tag";
import { useParams } from "react-router-dom";
import { useGet } from "@hooks/apiHooks.ts";
import { CandidateData } from '@/network/definitions/job';

export const filterConstant = {
    ALL: 'all',
    SELECTED: 'approved'
};

const CandidateTable = ({ filter }) => {
    const [css] = useStyletron();
    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = React.useState(true);
    const [pageNumber, setPageNumber] = React.useState(1);
    const { id = null } = useParams();
    const pageSize = 20;

    const result = useGet<CandidateData>(
        `agency-api/job/${id}/candidates?filter=${filter}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        { filter, pageNumber, pageSize }
    );

    const data = result.data?.data || [];
    const paginationData = result.data?.paginationData;
    const success = result.data?.success || false;

    const changePage = (page: number) => {
        setLoading(true)
        setPageNumber(page);
    };

    React.useEffect(() => {
        if (success || data) {
            setLoading(false);
        }
    }, [data, success]);

    const onPageChange = (nextPage: number) => {
        const page = Math.min(Math.max(nextPage, 1), paginationData?.totalPages || 1)
        setCurrentPage(page);
        if (typeof changePage === 'function') {
            changePage(page)
        }
    }

    // Function to get columns based on filter
    const getColumns = () => {
        if (filter === filterConstant.ALL) {
            return ["Email", "Name", "Job role", "Profile review", "Interview status", "Onboarding status", "Uploaded on"];
        } else if (filter === filterConstant.SELECTED) {
            return ["Email", "Name", "Onboarding status", "Trial start date", <span>Final talent<br />rate(USD)</span>, "Uploaded on"];
        }
    };

    const getApplicationReviewTag = (status) => {
        let tagKind;
        let statusText;
        if (status?.toLowerCase() === 'approved') {
            tagKind = TagKind.positive;
            statusText = 'Approved';
        } else if (status?.toLowerCase() === 'rejected') {
            tagKind = TagKind.negative;
            statusText = 'Rejected';
        } else {
            tagKind = TagKind.warning
            statusText = 'Pending';
        }
        return <Tag closeable={false} kind={tagKind}>{statusText}</Tag>
    }

    // Function to get data based on filter
    const getFilteredData = () => {
        if (!data || !data.length) {
            return [];
        }
        if (filter === filterConstant.ALL) {
            return data.map((item) => ([
                item.email, item.name, item.job_title, getApplicationReviewTag(item.application_review_status), item.mti_status, item.onboarding_status, formatDate(item.uploaded_date)
            ]));
        } else if (filter === filterConstant.SELECTED) {
            return data.map((item) => ([
                item.email, item.name, item.onboarding_status, formatDate(item.trial_start_date), item.final_developer_rate, formatDate(item.uploaded_date)
            ]));
        }
    };

    return (
        <FlexGrid flexDirection="column" gridGap="16px">
            <div className={css({
                overflowX: 'auto',
                width: '100%',
                whiteSpace: 'nowrap',
            })}>
                <Table
                    columns={getColumns()}
                    data={getFilteredData()}
                    size={SIZE.spacious}
                    emptyMessage={<h1>No data</h1>}
                    divider={DIVIDER.grid}
                    isLoading={loading || result.isLoading}
                    loadingMessage={<Spinner $size='30px' />}
                    overrides={{
                        TableHeadCell: {
                            style: {
                                backgroundColor: '#f0f0f0',
                                fontWeight: 'bold',
                            },
                        },
                    }}
                />
            </div>
            <Pagination
                size={PaginationSize.compact}
                numPages={paginationData?.totalPages || 1}
                currentPage={currentPage}
                onPageChange={({ nextPage }) => onPageChange(nextPage)}
            />
        </FlexGrid>
    );
};

export default CandidateTable;
